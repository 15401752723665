<template>
  <main class="view view--week">
    <section class="header">
      <div class="header__left"></div>

      <div class="header__center">
        <p class="title">{{ this.$helper.ucfirst(date.format("dddd")) }}</p>
        <p class="subtitle">{{ date.format("DD/MM/YYYY") }}</p>
      </div>

      <div class="header__right">
        <button
          v-on:click="
            $router.push({
              name: 'overview',
              query: { date: date.format('YYYY-MM-DD') },
            })
          "
          class="btn btn-close"
        ></button>
      </div>
    </section>
    <div>
      <div
        class="timeregistration-summary timeregistration-summary-full"
        v-if="day_total > 0 || pause_total > 0"
      >
        <span v-if="day_total > 0"
          >{{ $t("Total") }}:
          <strong>{{
            $moment
              .duration(day_total, "milliseconds")
              .format("HHumm", { trim: false })
          }}</strong></span
        >
        <span v-if="pause_total > 0"
          >{{ $t("Break") }}:
          <strong>{{
            $moment
              .duration(pause_total, "milliseconds")
              .format("HHumm", { trim: false })
          }}</strong></span
        >
      </div>
      <div class="message" v-if="timetrackings.length == 0">
        <div class="message__text">
          {{ $t("There are no trackings for the selected date.") }}
        </div>
      </div>
      <section v-if="timetrackings.length > 0" class="tasks">
        <div
          class="task"
          v-for="(tracking, index) in timetrackings"
          :key="index"
        >
          <div class="task__time">
            {{ formatHour(tracking.start)
            }}<span>&nbsp;-&nbsp;{{ formatHour(tracking.end) }}</span>
          </div>
          <div class="task__info">
            <strong class="single-line">{{ tracking.task.title }}</strong
            ><br /><em
              class="single-line"
              v-if="tracking.task.relatable_type == 'project'"
              ><span v-if="tracking.task.relatable.internal_id"
                >{{ tracking.task.relatable.internal_id }} - </span
              >{{ tracking.task.relatable.title }}</em
            >
          </div>
        </div>
      </section>
    </div>
  </main>
</template>

<script>
export default {
  name: "overview",
  mounted: function () {
    if (!this.$route.query.date) {
      this.$router.push({ name: "home" });
      return;
    }

    var self = this;

    this.$api
      .getTrackings(
        this.account.id,
        this.date.format("YYYY-MM-DD"),
        this.date.format("YYYY-MM-DD")
      )
      .then((timetrackings) => {
        var day_trackings = timetrackings[this.date.format("YYYY-MM-DD")];
        for (let i = 0; i < day_trackings.length; i++) {
          const slot = day_trackings[i];
          if (slot.tracking) {
            var tracking = slot.tracking;
            let end = this.$moment(tracking.end, "HH:mm:ss");
            let start = this.$moment(tracking.start, "HH:mm:ss");
            var duration = this.$moment
              .duration(end.diff(start))
              .subtract(tracking.pause);
            tracking.total = duration.asMilliseconds();
            this.timetrackings.push(tracking);
            this.day_total += tracking.total;

            let pause_duration = this.$moment.duration(tracking.pause);
            this.pause_total += pause_duration;
          }
        }
      })
      .catch(function () {
        self.$database.readAllData("timetrackings").then((timetrackings) => {
          console.log(timetrackings);
        });
      });
  },
  data: function () {
    return {
      account: this.$config.get("account"),
      date: this.$moment(this.$route.query.date),
      day_total: 0,
      pause_total: 0,
      timetrackings: [],
    };
  },
  methods: {
    formatHour: function (time) {
      return this.$moment(time, "HH:mm:ss").format("HHumm");
    },
  },
};
</script>
